// Various utility functions, some created by PW and some brought in from the intertubes (all open source)
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

/** Checks whether a value is empty, defined as null or undefined or "".
 *  Note that 0 is defined as not empty.
 *  @param {*} val - value to check
 *  @returns {boolean}
 */
window.empty = function(val) {
	// you can also call this fn as empty(o, 'foo', 'bar'), which will return true if:
	// - o is empty OR o is not an object
	// - o.foo is empty OR o.foo is not an object
	// - o.foo.bar is empty OR o.foo.bar is not an object
	// this simplifies an if clause like `if (empty(o) || empty(o.foo) || empty(o.foo.bar))` to `if (empty(o, 'foo', 'bar'))`
	if (arguments.length > 1) {
		if (empty(val) || typeof(val) != 'object') {
			return true
		}

		// if we get to here, arguments[1] might be an array or the first of a series of strings; handle both cases
		let args
		if ($.isArray(arguments[1])) {
			args = arguments[1]
		} else {
			// copy arguments, then use splice to get everything from index 1 on
			args = $.merge([], arguments).splice(1)
		}

		// if we're here, we know that val is itself an object
		if (empty(val[args[0]])) {
			return true
		} else if (args.length > 1) {
			// shift the first value out of args and recurse
			val = val[args[0]]
			args.shift()
			return empty(val, args)
		}
		return false
	}

	// note that we need === because (0 == "") evaluates to true
	return (val === null || val === "" || val === undefined)
}

// look for a nested property of an object; if any property in the list is empty, return null
// obj = {alpha: {bravo:'charlie'}}
// oprop(obj, 'alpha', 'bravo') // == 'charlie'
window.oprop = function(obj) {
	if (empty(obj)) return null
	if (arguments.length == 1 || typeof(obj) != 'object') return obj

	// if we get to here, arguments[1] might be an array or the first of a series of strings; handle both cases
	let args
	if ($.isArray(arguments[1])) {
		args = arguments[1]
	} else {
		// copy arguments, then use splice to get everything from index 1 on
		args = $.merge([], arguments).splice(1)
	}

	// if args[0] isn't a property of obj, return null
	if (empty(obj[args[0]])) {
		return null

	// if we still have more than 1 arg, shift the first value out of args and recurse
	} else if (args.length > 1) {
		obj = obj[args[0]]
		args.shift()
		return oprop(obj, args)

	} else {
		return obj[args[0]]
	}
}

/** Return val or default_val, depending on whether val isn't or is empty (as defined by window.empty above)
 *  SHORTCUT: dv()
 */
window.default_value = function(val, default_val) {
	if (!empty(val)) return val
	return default_val
}
window.dv = window.default_value

/** Set a property of an object to a default value if the property is currently empty (as defined by window.empty above)
 *  SHORTCUT: sdp()
 *  Two versions:
 *      1. sdp(o, 'foo', 'bar')
 *          - sets o.foo to 'bar', unless o.foo is already set
 *      2. sdp(o, p, 'foo', 'bar')
 *          - if p.foo is set, then set o.foo to p.foo; otherwise set o.foo to 'bar'
 *
 *  Also do some limited type checking: if default_val is a Boolean or a number, make sure the value we ultimately set
 *      is a Boolean or number; if converting it to the correct type fails, throw an error
 *  Version 2 can also include a "legal_vals" array, for enumeration checking
 */
window.set_default_property = function(o) {
	var prop, default_val, val, legal_vals
	if (arguments.length >= 4) {
		legal_vals = arguments[4]
		default_val = arguments[3]
		prop = arguments[2]
		var p = arguments[1]
		if (!empty(p) && !empty(p[prop])) val = p[prop]
		else val = default_val
	} else {
		prop = arguments[1]
		default_val = arguments[2]
		if (!empty(o[prop])) val = o[prop]
		else val = default_val
	}

	// if default_val is true or false, make sure the value we set is also a boolean
	if (default_val === true || default_val === false) {
		if (val === 'true') val = true
		if (val === 'false') val = false
		if (val != true && val != false) {
			if (typeof(prop) == 'object') prop = '[object]'
			throw new Error(sr('Boolean argument expected for property $1; $2 received', prop, val))
		}
		// convert 1/0 to true/false
		val = (val == true)

	// if default_val is a number, make sure the value we set is also a number
	} else if (typeof(default_val) == 'number') {
		let new_val = val * 1
		if (isNaN(new_val)) {
			throw new Error(sr('Numeric argument expected for property $1; $2 received', prop, val))
		}
		val = new_val
	}

	// if we got legal_vals (which must be an array), check to make sure the value is one of those; use default_val otherwise
	if (!empty(legal_vals)) {
		if (legal_vals.find(x => x == val) == null) {
			console.log(sr('illegal value found for prop “$1”: “$2”', prop, val))
			val = default_val
		}
	}

	o[prop] = val
}
window.sdp = window.set_default_property

/** Replace variables in a string, a la PHP
 * e.g.:
 * str_replace("replace value $bar.", {bar: "foo"})
 *    =>
 * "replace value foo."
 *
 * o.bar can be either a scalar value or a function that returns a scalar value
 *
 * Or, you can pass variables in directly, and specify them with $1, $2, $3, etc.
 * str_replace("replace value $1.", "foo")
 *    =>
 * "replace value foo."
 *
 * SHORTCUT: sr()
 *
 *  @param {string} s
 *  @param {object|array} [o] - if this is an array, it will be assumed to be an array of objects. if this is not an array or an object, it will treat the arguments array as a list of scalars
 *  @returns {*}
 */
window.str_replace = function(s, o) {
	// if o is an array, recursively process each object in the array
	if ($.isArray(o)) {
		for (var i = 0; i < o.length; ++i) {
			s = str_replace(s, o[i])
		}

	} if (typeof(o) == "object") {
		// find all instances of $xxx
		var matches = s.match(/\$(\w+)\b/g)
		if (!empty(matches)) {
			for (var i = 0; i < matches.length; ++i) {
				var key = matches[i].substr(1)
				var val = null
				// scalars
				if (typeof(o[key]) == "string" || typeof(o[key]) == "number") {
					val = o[key]
				} else if (typeof(o[key]) == "function") {
					val = o[key]()
				}
				if (val !== null) {
					var re = new RegExp("\\$" + key + "\\b", "g")
					s = s.replace(re, val)
				}
			}
		}
	} else {
		for (var i = 1; i < arguments.length; ++i) {
			var re = new RegExp("\\$" + i + "\\b", "g")
			s = s.replace(re, arguments[i])
		}
	}
	return s
}
// shortcut
window.sr = str_replace

// shortcut for doing a jquery extend; this is useful for console.logging vue reactive objects
window.extobj = function(o, stringify) {
	o = $.extend(true, {}, o)
	if (stringify) {
		o = JSON.stringify(o, null, 4)
		console.log(o)
	}
	return o
}
// actually this works better in many circumstances
window.object_copy = function(o, stringify) {
	o = JSON.parse(JSON.stringify(o))
	if (stringify) return JSON.stringify(o, null, 4)
	return o
}

window.U = {}

/* ------------------------------------------------------
  Note about sesson IDs:
  Sparkl creates a separate session ID for every activity launch. 
  That is, if a user opens one Sparkl activity, then immediately opens another Sparkl activity, we create separate session IDs for each activity.
  Each session ID is created as part of the LTI launch process, and passed in to the client via the get string of the url.
  However, we don't want to have the session ID in the browser's URL box, 
  because that might lead the user to bookmark or send the url with the session ID in the URL, which would obviously be bad.
  So here's the process we use to handle this:
    1. As utilities.js (this file) is initially processed, the code below runs, which does the following to save a value for U.session_id:
	   a. If the get string of the url includes a session_id, it extracts the session_id from the get string
	   b. Otherwise if it recognizes that this is an activity launch (because it sees "/\d" in the url), it looks for a session_id in localStorage for this activity
    2. U.ajax, through which we route all service calls, sends U.session_id through to the server
	3. The initialize services (initialize_teacher and initialize_student) pass a "normalized_url" to the client, 
	   which represents what we want the user to see in the client's URL box. The initialize fn in store calls U.normalize_url to set the URL accordingly.
	   (Note: the "embedded" flag is sent to the client separately, via the get string, from sparkl_lti_launch, because we want to initialize the postMessage connection asap,
	   so the client adds the embedded flag to the normalized_url it gets back from the server)
	4. If a user has initiated their session via a login with Google, when they sign out we run clear_all_session_ids to clear all session IDs from localstorage
	5. For launches from an LTI platform, if the user re-launches an activity, a new session ID should be generated and will replace the old session ID in localstorage
------------------------------------------------------ */

// try to get PHPSESSID out of get string, then from local storage
U.session_id = ''
if (window.location.search.search(/.*PHPSESSID=(\w+).*/) > -1) {
	U.session_id = RegExp.$1
// if not found in get string, look in localstorage
} else if (window.location.pathname.search(/^\/(\d+)\b/) > -1) {
	let activity_id = RegExp.$1
	U.session_id = window.localStorage.getItem('sparkl_session_id-' + activity_id)
	if (empty(U.session_id)) U.session_id = ''
}

// normalize the url for a sparkl activity. this should be called as soon as we know the activity_id for the activity
U.normalize_url = function(activity_id, normalized_url) {
	// if we have a PHPSESSID, store it in localstorage at this time
	if (!empty(U.session_id)) {
		// can't set to localStorage in safari incognito mode
		try {
			window.localStorage.setItem('sparkl_session_id-' + activity_id, U.session_id)
		} catch(e) {
			console.log('error in normalize_url', e)
		}
	}

	// normalized_url value should be sent in from the server and passed to this fn
	history.replaceState(null, '', window.location.origin + '/' + normalized_url);
}

// check to make sure we still have the session id in localstorage
// if, for example, a teacher opens a preview window and logs out from there, this will tell us they're logged out
U.check_session_id = function(activity_id) {
	return !empty(window.localStorage.getItem('sparkl_session_id-' + activity_id))
}

// clear all session id's. This should be done if/when the user signs out
// we clear the session id's for *all* activities because if the user signs out, they're signing out of everything
// note that for LTI launches from external consumers (e.g. LMSs), the user is not given the option to sign out
// also note that this doesn't work in npm run serve mode, because since the ports are different the portal app doesn't have access to the teacher/student app's localstorage vars
U.clear_all_session_ids = function() {
	let l = window.localStorage.length
	for (let i = l-1; i >= 0; --i) {
		let key = window.localStorage.key(i)
		if (key.indexOf('sparkl_session_id-') == 0) {
			window.localStorage.removeItem(key)
		}
	}
}

// we use this to transmit data to services when the data might include html that might get flagged/rejected by firewalls (e.g. saving Sparkl exercises to the GaDOE velocity server)
U.encode_blocked_keywords = function(s) {
	s = s.replaceAll('iframe', 'ifxzxzxrame')
	s = s.replaceAll('embed', 'emxzxzxbed')
	s = s.replaceAll('object', 'obxzxzxject')
	s = s.replaceAll('http', 'htxzxzxtp')		// this will also deal with "https"
	s = s.replaceAll('script', 'scxzxzxript')	// this will also deal with "javascript"
	s = s.replaceAll('onclick', 'oncxzxzxlick')
	s = s.replaceAll('style', 'stxzxzxyle')
	s = s.replaceAll('data:image/webp;base64', 'encodedxzxzximage')

	return s
}

U.ajax = function(service_name, data, callback_fn, override_options) {
	// create url with Tsugi PHPSESSID, and add service_name to data
	var url
	// local development with 'npm run serve'
	if (window.location.host.indexOf('localhost') > -1) {
	 	url = "/src/ajax.php"
	// server, or local testing with 'npm run build'
	} else {
		url = "/src/ajax.php"
	}
	if (!empty(U.session_id)) url += "?PHPSESSID=" + U.session_id

	data.service_name = service_name

	// new (1/2021) version of services
	data.service_name = 'sparkl_new/' + data.service_name

	var options = {
		type: "POST",
		url: url,
		cache: false,
		data: data,
		dataType: "text",
		success: function(str, text_status) {
			var result
			if (empty(str)) {
				result = {"status": "Ajax returned with no status"}
			} else {
				try {
					result = JSON.parse(str)
				} catch(e) {
					result = {"status": str}
				}
			}

			if (empty(result.status)) {
				result.status = "Ajax returned with no status"
			}

			if (result.status == 'maintenance') {
				U.maintenance_protocol()
				return
			}

			if (result.status != "ok") {
				// error
				console.log("ajax success but not 'ok'", result)
			}

			if (!empty(callback_fn)) {
				callback_fn(result)
			}

		},
		error: function(jqXHR, textStatus, errorThrown) {
			// let caller handle expired session notices
			// if (jqXHR.responseText == 'Session expired - please re-launch') {
			// 	alert('Your session has expired.')
			// }

			let status = 'Ajax server error'

			// if response includes the word 'blocked', it could be a firewall issue
			if (jqXHR.responseText.search(/blocked/i) > -1) {
				// modify the status message, and send a new message to the server that will log the issue
				status = 'Ajax server error: POSSIBLE FIREWALL ISSUE'
				// we have to extract just the plain text of jqXHR.responseText before we send to log_firewall_issue; otherwise this service might get blocked too!!
				let report_string = U.html_to_text(jqXHR.responseText.replace(/[\s\S]+(<body[\s\S]+body>)[\s\S]*/, '$1'))
				console.warn(status, report_string)
				if (report_string == jqXHR.responseText) report_string = 'TEXT NOT REPORTABLE'
				U.ajax('log_firewall_issue', {responseText: report_string})
			}

			var result = {
				"status": status,
				"ajax_name": service_name,
				"textStatus": textStatus,
				"errorThrown": errorThrown,
				"responseText": jqXHR.responseText
			}

			if (!empty(callback_fn)) {
				callback_fn(result)
			}
		}
	}

	// override any options coming in
	if (!empty(override_options)) {
		for (var key in override_options) {
			options[key] = override_options[key]
		}
	}

	$.ajax(options)
}

U.reload_window = function() {
	// if we're embedded in Cureum, call a fn to do this, so that it reestablishes the postmessage connection
	if (vapp.$store.state.embedded_mode) {
		console.log("vapp.pm_send('reload_activity')")
		vapp.pm_send('reload_activity')
	} else {
		document.location.reload()
	}
}

U.maintenance_protocol = function() {
	alert('The Sparkl system is temporarily down for maintenance.')
	if (window.location.hostname == 'localhost') {
		window.location = 'http://local.pw-et.com';
	} else {
		window.location = '/';
	}
}

U.loading_start = function(msg, identifier) {
	$('#spinner-wrapper').show()
	if (!empty(msg)) {
		$('#spinner-wrapper').append(sr('<div id="spinner-wrapper-msg" style="text-align:center;margin:20% 30px 0 30px;font-size:24px;font-weight:bold;font-family:sans-serif;color:#fff;">$1</div>', msg))
	}

	// if we receive an identifier, set U.loading_start_identifier to make sure we don't close the loading message too soon
	if (typeof(identifier) == 'string') {
		U.loading_start_identifier = identifier
	} else {
		U.loading_start_identifier = ''
	}
}

U.loading_stop = function(identifier) {
	// see above
	if (typeof(identifier) == 'string') {
		if (U.loading_start_identifier != identifier) {
			return
		}
	}

	$('#spinner-wrapper').hide()
	$('#spinner-wrapper-msg').remove()
}

U.key_count = function(o) {
	return Object.keys(o).length
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 * https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 */
U.shuffle_array = function(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
}

// if one argument is specified, it's max and we return >= 0  and < max (use for an array index, e.g.)
// if two arguments are specified, they're min and max, and we return >= min and <= max
// if the first argument is a function, assume it's a random number generator to use instead of Math.random()
U.random_int = function() {
	let rng = Math.random
    let args
	if (arguments.length > 0 && typeof(arguments[0]) == "function") {
		rng = arguments[0]
        args = [arguments[1], arguments[2]]
	} else {
        args = arguments
    }

	if (empty(args[0])) return 0

	let min, max
	if (empty(args[1])) {
		min = 0
		max = args[0] * 1
	} else {
		min = args[0] * 1
		max = args[1] * 1 + 1
	}
	if (isNaN(min) || isNaN(max)) {
		return 0
	}
	return min + Math.floor(rng() * (max-min))
}

U.word_count = function(text) {
	if (empty(text)) return 0
	text = '' + text

	// remove entities
	text = text.replace(/\&[#\w]+/g, ' ')

	// insert spaces at breaks
	text = text.replace(/\b/g, ' ')

	// remove tags
	text = text.replace(/<.*?>/g, ' ')

	// remove non-letters
	text = text.replace(/[^\w\s]/g, '')

	// trim
	text = $.trim(text)

	// if text is empty at this point, no words
	if (!text) return 0

	// split on spaces and return count
	let arr = text.split(/\s+/)
	return arr.length;
}

U.is_workspace_response = function(s) {
	// determine if the string is a "workspace" response (e.g. the response to a draw-type CR prompt)
	if (typeof(s) != 'string') return false
	return s.search(/^[A-Z]\[/) == 0
}

// created Feb 2025 for use in workspaces with dd_canvas parts; but then not used; kept here in case we can use it for something later
// U.generate_workspace_element_id = function(existing_ids) {
// 	// generate an id for this element that doesn't already exist in the existing_ids
// 	// the algorithm generates a 3-letter id, with 15600 possible values; should be ample
// 	let id = ''
// 	while (empty(id)) {
// 		let arr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
// 		U.shuffle_array(arr)
// 		id = arr[0] + arr[1] + arr[2]
// 		if (existing_ids.includes(id)) id = ''
// 	}
// 	return id
// },

U.create_audio_response = function(recording_length, audio_playback_url, text) {
	return `[[audio:${recording_length} - ${audio_playback_url}]] ${text}`
},

U.parse_audio_response = function(s) {
	if (typeof(s) != 'string') return null
	let arr = s.match(/\[\[audio:([\d.]+) - (.*)\]\]\s*(.*)/)
	if (empty(arr)) return null
	return { time: arr[1], url: arr[2], text: arr[3] }
}

U.is_audio_response = function(s) {
	// determine if the string is a "audio" response (e.g. the response to a audio-type CR prompt)
	if (typeof(s) != 'string') return false
	return !empty(U.parse_audio_response(s))
}

U.copy_to_clipboard = function(s) {
	// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
	// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
	let fallback = (s) => {
		$('body').append('<textarea class="k-copy-to-clipboard-input-textarea"></textarea>')
		let jq = $('body').find('.k-copy-to-clipboard-input-textarea')
		jq.val(s)
		jq[0].select()
		document.execCommand("copy")
		jq.remove()
	}

	if (!navigator.clipboard) {
		fallback(s)
	} else {
		navigator.clipboard.writeText(s).then(function() {
			console.log('Async: Copy to clipboard was successful')
		}, function(err) {
			console.error('Async: Could not copy text; trying fallback', err)
			fallback(s)
		})
	}
}

U.read_from_clipboard = function() {
	return new Promise((resolve, reject)=>{
		if (!navigator.clipboard) {
			reject('no_access_to_clipboard')
		} else {
			navigator.clipboard.readText()
			.then((s) => { resolve(s) })
			.catch(err => { console.log('read_from_clipboard errror', err); reject(err); })
			// note: error text will be available in err.message, e.g. 'Read permission denied.'
		}
	})
}

// plural string / plural string with number
// ps('word', 1) => 'word'
// ps('word', 2) => 'words'
// psn('word', 2) => '2 words'
// ps('a word', 2, 'the words') => 'the words'
U.ps = function(s, val, plural_s) {
	if (val*1 == 1) return s
	if (!empty(plural_s)) return plural_s
	return s + 's'
}
U.psn = function(s, val, plural_s) {
	s = U.ps(s, val, plural_s)
	return sr('$1 $2', val, s)
}

U.capitalize_word = function(s) {
	if (empty(s) || typeof(s) != 'string') return ''
	return s[0].toUpperCase() + s.substr(1)
}

U.remove_accents = function(str) {
	const accents     = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
	const accents_out = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
	str = str.split('')
	let sl = str.length
	let i, x
	for (i = 0; i < sl; i++) {
		if ((x = accents.indexOf(str[i])) != -1) {
			str[i] = accents_out[x]
		}
	}
	return str.join('')
}

// parse and return unique characters in a string
// note that the last copy of each repeated letter will be returned
// note that this is case sensitive -- U.unique_chars('fFoobbar') returns 'fFobar'; use U.unique_chars(s.toLowerCase) to convert to lc first
U.unique_chars = function(s) {
	return s.replace(/(.)(?=.*\1)/g, "")
}

U.ordinal_string = function(i) {
    let j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

// use jquery to strip html and convert entities to text
U.html_to_text = function(html) {
	return $(`<div>${html}</div>`).text()
}

// remove 'ZERO WIDTH SPACE' (U+200B; decimal 8203) characters from a string; I think these are being inserted via froala and/or jquery, and they can screw up hangman (and possibly other) queries
// we might need to add additional char checks later
// https://stackoverflow.com/questions/2973698/whats-html-character-code-8203
// https://stackoverflow.com/questions/11305797/remove-zero-width-space-characters-from-a-javascript-string
U.remove_zero_width_chars = function(s) {
	if (empty(s)) return s
	return s.replace(/\u200B/g, '')
}

U.get_block_width = function(val, cls, style, tag) {
	if (!style) style = ''
	if (!tag) tag = 'div'
	// let $sizer = $(`<${tag} class="${cls}" style="width:auto; position:fixed; left:200px; top:200px">${val}</${tag}>`)
	let $sizer = $(`<${tag} class="${cls}" style="width:auto; position:fixed; left:-2000px; top:-2000px; ${style}">${val}</${tag}>`)
	$('html').append($sizer)
	let w = $sizer.outerWidth()
	$sizer.remove()
	return w
}

// add click events to images to allow them to be maximized. used in studentapp (and teacherapp preview)
U.enable_image_maximize = function($jq) {
	$jq.find('img[data-cglt],img.fr-fic')
		.css('cursor', 'pointer')
		.off('click').on('click', function() { 
			// add click event
			vapp.expand_image(this)
		}).each(function() {
			const $jq = $(this)

			// don't do this more than once for each image
			if ($jq.attr('auto_added_alt_text') == 'yes') return

			// if we don't have any alt text for the image, just add 'Activity image'
			let alt_text = $jq.attr('alt')
			if (empty(alt_text)) {
				alt_text = 'Activity image'
				// the expanded image component uses this to decide whether or not to show the alt text
				$jq.attr('auto_added_alt_text', 'yes')
				$jq.attr('alt', alt_text)
			}

			// set title so that we show the alt_text on hover
			if (empty($jq.attr('title'))) {
				$jq.attr('title', alt_text)
			}

			// add uuid for opening in a new window
			$jq.attr('uuid', U.new_uuid())
		})
}

// this is called in froala-plugin and VideoQueryEditor to try to get iframeable urls from original urls
U.get_iframeable_video_url = function(url) {
	// for google drive urls, convert to an iframeable version
	// https://drive.google.com/file/d/1x9CnpdZcXwBXcuxQD6Gxi55I6vBkrlYC/view
	if (url.search(/https:\/\/drive.google.com\/file\/d\/(.*?)\//) > -1) {
		url = `https://drive.google.com/file/d/${RegExp.$1}/preview`

	} else if (url.search(/https:\/\/drive.google.com\/uc?id=(.*?)(&|$)/) > -1) {
		url = `https://drive.google.com/file/d/${RegExp.$1}/preview`

	// Vimeo: https://vimeo.com/channels/shortoftheweek/1021931365
	// https://vimeo.com/673608912
	} else if (url.search(/https:\/\/[^\/]*?vimeo.com\b.*\/(.+?)(\?|$)/) > -1) {
		url = `https://player.vimeo.com/video/${RegExp.$1}`

	// loom: https://www.loom.com/share/ea524cd1244e4303b9df80bfeb6a4364?t=0
	} else if (url.search(/https:\/\/.*?loom.com\/share\/(.+?)(\?|$)/) > -1) {
		url = `https://www.loom.com/embed/${RegExp.$1}`
	}
	// we can add additional transforms here as needed

	return url
}

// this is used for iframed videos (or non-video urls) that aren't youtube
U.add_video_iframe_footer = function($parent) {
	// pass the parent element's jquery in; this will first remove any previously-added footers, and then add footers to the iframes
	$parent.find('.k-exercise-video-iframe-footer').remove()
	$parent.find('.k-exercise-video-iframe').each((index, el)=>{
		let url = $(el).attr('src')
		let html = `<div class="k-exercise-video-iframe-footer"><a href="${url}" target="_blank"><i class="fas fa-arrow-up-right-from-square mr-2" style="font-size:16px"></i>Open in New Tab</a></div>`
		$(el).after(html)
	})
}

// try to extract a youtube ID from a url
U.youtube_id_from_url = function(url) {
	// the 'url' could already be an id alone; but try to extract the url from known forms of youtube urls
	// https://www.youtube.com/watch?v=jofNR_WkoCE&foo
	let video_id = url.replace(/.*\bv=([A-Za-z0-9_-]+).*/, '$1')
	// https://youtu.be/BtN-goy9VOY
	if (video_id == url) video_id = url.replace(/.*youtu\.be\/([A-Za-z0-9_-]+).*/, '$1')

	// if what we have at this point contains any characters not in youtube IDs, return an empty string
	if (!U.is_valid_youtube_id_format(video_id)) {
		return ''
	}

	// otherwise return the video_id
	return video_id

	// caller can check against the empty string to see if it's a valid url or "bare" id; this will generally work fine
}

U.is_valid_youtube_id_format = function(video_id) {
	if (typeof(video_id) != 'string' || empty(video_id)) return false
	return video_id.search(/[^A-Za-z0-9_-]/) == -1
}

// https://stackoverflow.com/a/63698925
U.find_highest_z = () =>
  [...document.querySelectorAll('body *')]
	.map(elt => parseFloat(getComputedStyle(elt).zIndex))
	.reduce((highest, z) => z > highest ? z : highest, 1)

// set the following to true to debug latex
U.verbose_latex = false

// partial results of experiments with other rendering systems are still here...
// U.latex_rendering_system = 'katex'
// U.latex_rendering_system = 'mathjax'
// U.latex_rendering_system = 'mathlive-delayed'
U.latex_rendering_system = 'mathlive'

U.inject_mathlive_styles = function() {
	// call this when the app is initialized; we do it this way so that we can also include the styles in print view
	// this is modeled on the `injectStylesheet` fn in https://github.com/arnog/mathlive/blob/master/src/common/stylesheet.ts
	// we have a static version of the MathLive core css file in `mathlive_core_css.js` (included in main.js), set to U.mathlive_core_css
	// (adapted from https://github.com/arnog/mathlive/blob/49f11e27e44cb71f02fbcd99336199e41335a1c7/css/core.less)
	const styleNode = window.document.createElement('style');
    styleNode.id = `mathlive-style-core`;
    styleNode.append(window.document.createTextNode(U.mathlive_core_css));
    window.document.head.appendChild(styleNode);
}

U.render_latex = function(s, added_span_attributes) {
	if (empty(s)) return ''
	
	// skip expensive regexp if no latex
	if (!s.includes('$')) return s

	let original_s = s
	// by convention, latex is coded like this:
	// For example, we define $5^{(1/3)}$ to be the cube root of 5 because we want $[5^{(1/3)}]^3$ = $5^{[(1/3) x 3]}$ to hold, so $[5^{(1/3)}]^3$ must equal 5.
	s = '[' + s + ']'
	s = s.replace(/((<p[^>]*>\s*)|[\s({\[>]|\&nbsp;)\$(\S([^$]*?\S)?)\$(((\&nbsp;)?\s*<\/p[^>]*>)|[\s)}\].,;:%!?<]|\&nbsp;)/g, ($0, $start, $x1, $latex, $x2, $end) => {
		if (original_s.includes('s a formula')) console.log($0, $start, $x1, $latex, $x2, $end)
		// note that we search for $ followed immediately by a non-space char at the start, and a non-space char followed immediately by $ at the end
		// also there needs to be a space, >, or an opening paren, bracket, or brace before the opening $, then a space, <, a closing paren, bracket, or brace, or a punctuation mark after the closing $
		// (this is why we add [] around the full string at the start and take the [] back out below; this makes the fn work if the string has a LaTeX formula at the start or end of the string)
		// we render everything between the two $'s, and replace everything including the $'s with the rendered html

		let ks
		if (U.latex_rendering_system == 'mathjax') {
			// let mathjax_options = {em: 12, ex: 6, display: false, containerWidth:100, scale:20}	// https://docs.mathjax.org/en/latest/web/typeset.html
			let mathjax_options = {display: false}	// https://docs.mathjax.org/en/latest/web/typeset.html
			ks = MathJax.tex2svg($latex, mathjax_options)
			if (ks) ks = ks.outerHTML	// MathJax will return DOM

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($latex, ks)

			return $start + ks + $end

		} else if (U.latex_rendering_system == 'mathlive-delayed') {
			// ks = `<span data-latex="${$latex}$" aria-hidden="true" translate="no" style="display: inline-flex;">${MathLive.convertLatexToMarkup($latex)}</span>`
			// ks = MathLive.convertLatexToMarkup($latex)
			ks = `\\(${$latex}\\)`

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($latex, ks)

			return $start + ks + $end

		} else {
			if (U.verbose_latex) console.log($latex)

			// dollar signs within equations need to elaborately coded so we find them properly; do a transform and then un-transform here to find them properly
			// This LaTeX formula includes dollar signs: $&amp;dollar;33 \times 2 = &amp;dollar;66$
			$latex = $latex.replace(/&amp;dollar;/g, '\\textnormal{DXD}')

			// replace other &amp;'s with &
			$latex = $latex.replace(/&amp;/g, '&')

			// replace &lt;/$gt;/etc. with latex codes
			$latex = $latex.replace(/&(lt|gt|le|ge|ne);/g, '\\$1')

			// take out \left and \right (?)
			$latex = $latex.replace(/\\(left|right)\b/g, '')

			// MathLive wants "textrm" instead of "rm"
			$latex = $latex.replace(/\\rm/g, '\\textrm')

			// replace newlines? currently seems that this isn't necessary
			// $latex = $latex.replace(/\\\\/g, '\\newline')

			// matrices
			$latex = $latex.replace(/\[ *\{\\begin\{array\}\{\*\{20}\{c\}\}(.*?)\\end\{array\}\} *\]/g, '\\begin{bmatrix}$1\\end{bmatrix}')
			$latex = $latex.replace(/\\begin\{array\}\{\*\{20}\{c\}\}(.*?)\\end\{array\}/g, '\\begin{matrix}$1\\end{matrix}')

			if (U.verbose_latex) console.log($latex)
			if (U.verbose_latex) console.log('------')
			
			// allow for added span attributes, which will be applied to any equation we process
			let span_attributes = added_span_attributes ?? ''
			if (U.latex_rendering_system == 'mathlive') {
				let mathstyle = ($start.match(/<p/) && $end.match(/<\/p/)) ? 'displaystyle' : 'textstyle'
				ks = MathLive.convertLatexToMarkup($latex, {mathstyle: mathstyle})	// mathstyle:'textstyle' uses 'inline' mode; alternative is 'displaystyle'

				// if we're using this method, add additional span_attributes when we generate the clearspeak_span
				span_attributes += ` data-latex="${$latex}" class="k-mathlive-span" aria-hidden="true" translate="no" style="display: inline-flex;"`
				// ks = `<span data-latex="${$latex}" class="k-mathlive-span" aria-hidden="true" translate="no" style="display: inline-flex;">${ks}</span>`
				// ks = `<span class="k-mathlive-span" aria-hidden="true" translate="no">${ks}</span>`

			} else {
				ks = window.katex.renderToString($latex, {throwOnError: false})
			}

			// we have to replace with the entity, instead of $, so that we don't re-process it below
			ks = ks.replace(/<span class="mord textrm">DXD<\/span>/g, '&dollar;')

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($latex, ks, span_attributes)

			return $start + ks + $end
		}
	})

	s = s.replace(/^\[([\s\S]*)\]$/, '$1')

	// if we made a change and there is still a $ in the string, we may need to run through the re again, so try that here
	// e.g. `$x = 0.4444444\ldots$ $9x = 4\ldots$`
	if (s != original_s && s.indexOf('$') > -1) {
		s = U.render_latex(s)
	}

	return s
}

U.clearspeak_span = function(latex_string, rendered_string, span_attributes) {
	// have to take out the 'displaystyle' 
	latex_string = latex_string.replace(/^\\displaystyle{(.*)}$/, '$1')
	// let cs = U.mathjax.generate_clearspeak(latex_string)
	let cs = MathLive.convertLatexToSpeakableText(latex_string)

	// transforms on clearspeak output
	// cs = cs.replace(/ backslash /g, ' ')	// when MathJax fails to render things, sometimes it will just print out " backslash degree"

	if (U.verbose_latex) console.log('clearspeak: ' + cs)

	// add additional span_attributes if provided
	if (!empty(span_attributes)) span_attributes = ' ' + span_attributes
	else span_attributes = ''

	// // adding a comma to the start and the end is necessary because otherwise, the screen reader will often ignore the initial letter in the equation (e.g. it will read “${g_n}$” as "sub n", skipping the "g")
	// return `<span aria-label=" , ${cs} , ">${rendered_string}</span>`
	return `<span aria-label="${cs}"${span_attributes}>${rendered_string}</span>`
}

U.preserve_latex = function(s) {
	if (U.latex_rendering_system == 'mathjax') return s

	// skip expensive regexp if no latex
	if (!s.includes('$')) return s

	// we have to preserve some special characters that marked will otherwise mess up
	s = '[' + s + ']'
	s = s.replace(/([\s({\[>])\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<])/g, ($0, $1, $2, $3, $4) => {
		// $2 is the LaTeX formula itself
		$2 = $2.replace(/ /g, 'KKXXKK')
		$2 = $2.replace(/\\/g, 'KKBSKK')
		$2 = $2.replace(/\&/g, 'KKAMPKK')
		$2 = $2.replace(/\*/g, 'KKASTKK')
		$2 = $2.replace(/\_/g, 'KKUNDKK')
		$2 = $2.replace(/\~/g, 'KKTILKK')
		return $1 + '$' + $2 + '$' + $4
	})
	s = s.replace(/^\[([\s\S]*)\]$/, '$1')
	return s
}

U.preserve_latex_reverse = function(s) {
	if (U.latex_rendering_system == 'mathjax') return s

	// skip expensive regexps if nothing was preserved
	if (!s.includes('KK')) return s
	
	s = s.replace(/KKTILKK/g, '~')
	s = s.replace(/KKUNDKK/g, '_')
	s = s.replace(/KKASTKK/g, '*')
	s = s.replace(/KKAMPKK/g, '&')
	s = s.replace(/KKBSKK/g, '\\')
	return s.replace(/KKXXKK/g, ' ')
}

U.marked_latex = function(s) {
	// preserve latex; then do marked, then unpreserve and render latex
	if (U.verbose_latex) console.log('original: ' + s)
	s = U.preserve_latex(s)
	if (U.verbose_latex) console.log('preserve: ' + s)
	s = marked(s)
	if (U.verbose_latex) console.log('  marked: ' + s)

	s = U.preserve_latex_reverse(s)
	if (U.verbose_latex) console.log('reversed: ' + s)
	s = U.render_latex(s)
	if (U.verbose_latex) console.log('=================')
	return s
}

// return a sortable numeric value for grade labels, which can be "g-K", "g-1", etc.
U.grade_val = function(g) {
	if (g == 'g-K') return 0
	return g.substr(2) * 1
}

// easy natural sort algorithm that actually seems to work!
// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
// arr.sort(U.natural_sort)
// arr.sort((a,b)=>U.natural_sort(a.prop_to_sort_by, b.prop_to_sort_by))
U.natural_sort = function(a, b) {
	return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
}

// formats seconds into "hh:mm:ss", with leading zeros inserted as appropriate and hours optional
U.time_string = function(seconds, round_seconds) {
	// by default we do round the seconds
	if (round_seconds !== false) {
		seconds = Math.round(seconds)
	}
	let hours = Math.floor(seconds / 3600)
	let minutes = Math.floor((seconds - hours*3600) / 60)
	seconds = seconds % 60
	if (seconds < 10) seconds = '0' + seconds
	let ts = sr('$1:$2', minutes, seconds)
	if (hours > 0) {
		if (minutes < 10) ts = '0' + ts
		ts = sr('$1:$2', hours, ts)
	}

	return ts
}

// generates RFC-compliant GUIDs
// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
// this is "e7" of Jeff Ward's answer
U.guid_lut = []; for (var i=0; i<256; i++) { U.guid_lut[i] = (i<16?'0':'')+(i).toString(16); }
U.new_uuid = function() {
	var d0 = Math.random()*0xffffffff|0;
	var d1 = Math.random()*0xffffffff|0;
	var d2 = Math.random()*0xffffffff|0;
	var d3 = Math.random()*0xffffffff|0;
	return U.guid_lut[d0&0xff]+U.guid_lut[d0>>8&0xff]+U.guid_lut[d0>>16&0xff]+U.guid_lut[d0>>24&0xff]+'-'+
		U.guid_lut[d1&0xff]+U.guid_lut[d1>>8&0xff]+'-'+U.guid_lut[d1>>16&0x0f|0x40]+U.guid_lut[d1>>24&0xff]+'-'+
		U.guid_lut[d2&0x3f|0x80]+U.guid_lut[d2>>8&0xff]+'-'+U.guid_lut[d2>>16&0xff]+U.guid_lut[d2>>24&0xff]+
		U.guid_lut[d3&0xff]+U.guid_lut[d3>>8&0xff]+U.guid_lut[d3>>16&0xff]+U.guid_lut[d3>>24&0xff];
}

U.is_letter = function(char) {
	return ('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(char) > -1)
}

U.is_valid_email = function(email) {
	// not RFC822-worthy, but easy
	if (email.search(/\s/) > -1) return false
	return email.search(/^[^@]+@[\w-\.]+$/) == 0
}

U.is_no_sign_in_email = function(email) {
	if (empty(email) || typeof(email) != 'string') return false
	return email.includes('no-sign-in.edu')
}

U.email_display = function(email) {
	// 'no-sign-in.edu' emails aren't really emails
	if (U.is_no_sign_in_email(email)) return 'name-only'
	return email
}

// utility fn to convert a "coded" numeric query answer to its numeric value (e.g. '1/4' => .25)
U.decode_numeric_query_answer = function(s) {
	// mostly legacy: convert 4/5 to a number
	if (s.search(/^([-.\d]+)\/([-.\d]+)$/) > -1) {
		return RegExp.$1 / RegExp.$2;
	}
	// convert to number if possible
	let n = s * 1
	if (!isNaN(n)) return n

	// if it's text, take out \mathrm{}
	s = s.replace(/\\mathrm{(.*?)}/g, '$1')
	return s
}

// utility fn to "encode" a number into the format used for numeric queries (?)
U.encode_numeric_query_answer = function(n) {
	// TODO: deal with symbols...
	let s = n + ''

	return s
}

// utility fn to determine if a string is a valid correct_answer for a numeric query
U.query_answer_is_numeric = function(s) {
	return !isNaN(this.decode_numeric_query_answer(s))
}

// 1/20/2024: this will not be used anymore once we move fully to mathtype
U.numeric_display_html = function(s) {
	s = s + ''

	// convert hyphen surrounded by spaces to n-dash
	s = s.replace(/ - /g, ' – ')

	// convert * to times
	s = s.replace(/\*/g, '×')

	// italicize letters
	s = s.replace(/(^|[^$])([a-zA-Z])/g, '$1<i>$2<xi>')

	// convert fractions
	let arr = s.split('/')
	if (arr.length == 2) {
		s = sr('<span class="k-fraction-wrapper"><span class="k-fraction-numerator">$1</span><span class="k-fraction-denominator">$2</span></span>', arr[0], arr[1])
	}

	s = s.replace(/xi/g, '/i')

	s = sr('<span class="k-math">$1</span>', s)

	return s
}

// utility fn to determine the number of places for a numeric value
U.num_places = function(n) {
	if (isNaN(n*1)) return 0

	n = '' + n
	if (n.search(/\.(\d+)$/) > -1) {
		return RegExp.$1.length
	}
	return 0
}

// https://github.com/trekhleb/javascript-algorithms/blob/master/src/algorithms/string/longest-common-substring/longestCommonSubstring.js
U.longest_common_substring = function(string1, string2) {
	// Convert strings to arrays to treat unicode symbols length correctly.
	// For example:
	// '𐌵'.length === 2
	// [...'𐌵'].length === 1
	const s1 = [...string1];
	const s2 = [...string2];

	// Init the matrix of all substring lengths to use Dynamic Programming approach.
	const substringMatrix = Array(s2.length + 1).fill(null).map(() => {
		return Array(s1.length + 1).fill(null);
	});

	// Fill the first row and first column with zeros to provide initial values.
	for (let columnIndex = 0; columnIndex <= s1.length; columnIndex += 1) {
		substringMatrix[0][columnIndex] = 0;
	}

	for (let rowIndex = 0; rowIndex <= s2.length; rowIndex += 1) {
		substringMatrix[rowIndex][0] = 0;
	}

	// Build the matrix of all substring lengths to use Dynamic Programming approach.
	let longestSubstringLength = 0;
	let longestSubstringColumn = 0;
	let longestSubstringRow = 0;

	for (let rowIndex = 1; rowIndex <= s2.length; rowIndex += 1) {
		for (let columnIndex = 1; columnIndex <= s1.length; columnIndex += 1) {
			if (s1[columnIndex - 1] === s2[rowIndex - 1]) {
				substringMatrix[rowIndex][columnIndex] = substringMatrix[rowIndex - 1][columnIndex - 1] + 1;
			} else {
				substringMatrix[rowIndex][columnIndex] = 0;
			}

			// Try to find the biggest length of all common substring lengths
			// and to memorize its last character position (indices)
			if (substringMatrix[rowIndex][columnIndex] > longestSubstringLength) {
				longestSubstringLength = substringMatrix[rowIndex][columnIndex];
				longestSubstringColumn = columnIndex;
				longestSubstringRow = rowIndex;
			}
		}
	}

	if (longestSubstringLength === 0) {
		// Longest common substring has not been found.
		return '';
	}

	// Detect the longest substring from the matrix.
	let longestSubstring = '';

	while (substringMatrix[longestSubstringRow][longestSubstringColumn] > 0) {
		longestSubstring = s1[longestSubstringColumn - 1] + longestSubstring;
		longestSubstringRow -= 1;
		longestSubstringColumn -= 1;
	}

	return longestSubstring;
}

// using the longest_common_substring fn above, get a decimal value (0.0-1.0) that reflects the amount of overlap between the two strings, taking the lengths of the strings into account
U.get_lcs_val = function(s1, s2) {
	if (empty(s1) || empty(s2)) return 0

	let lcs = U.longest_common_substring(s1, s2)

	// calculate % of the *harmonic mean* of the lengths of the left and right statements, then multiply by comp_factor_value
	// so if they're identical, we get 100%; if nothing matches we get 0%; harmonic mean is closer to the lower value than the upper value
	let lcs_den = 2 * s1.length * s2.length / (s1.length + s2.length)

	return lcs.length / lcs_den
}

// modified from:
// https://johnresig.com/projects/javascript-diff-algorithm/
U.diff_string = function ( o, n ) {
	function diff( o, n ) {
		var ns = {}
		var os = {}
	
		for ( var i = 0; i < n.length; i++ ) {
		  if (!ns.hasOwnProperty(n[i])) ns[ n[i] ] = { rows: [], o: null };
		  ns[ n[i] ].rows.push( i );
		}
	
		for ( var i = 0; i < o.length; i++ ) {
		  if (!os.hasOwnProperty(o[i])) os[ o[i] ] = { rows: [], n: null };
		  os[ o[i] ].rows.push( i );
		}
	
		for ( var i in ns ) {
		  if ( ns[i].rows.length == 1 && os.hasOwnProperty(i) && os[i].rows.length == 1 ) {
			n[ ns[i].rows[0] ] = { text: n[ ns[i].rows[0] ], row: os[i].rows[0] };
			o[ os[i].rows[0] ] = { text: o[ os[i].rows[0] ], row: ns[i].rows[0] };
		  }
		}
	
		for ( var i = 0; i < n.length - 1; i++ ) {
		  if ( n[i].text != null && n[i+1].text == null && n[i].row + 1 < o.length && o[ n[i].row + 1 ].text == null && 
			   n[i+1] == o[ n[i].row + 1 ] ) {
			n[i+1] = { text: n[i+1], row: n[i].row + 1 };
			o[n[i].row+1] = { text: o[n[i].row+1], row: i + 1 };
		  }
		}
	
		for ( var i = n.length - 1; i > 0; i-- ) {
		  if ( n[i].text != null && n[i-1].text == null && n[i].row > 0 && o[ n[i].row - 1 ].text == null && 
			   n[i-1] == o[ n[i].row - 1 ] ) {
			n[i-1] = { text: n[i-1], row: n[i].row - 1 };
			o[n[i].row-1] = { text: o[n[i].row-1], row: i - 1 };
		  }
		}
	
		return { o: o, n: n };
	}
  
	o = o.replace(/\s+$/, '');
	n = n.replace(/\s+$/, '');
  
	var out = diff(o == "" ? [] : o.split(/\s+/), n == "" ? [] : n.split(/\s+/) );
  
	var oSpace = o.match(/\s+/g);
	if (oSpace == null) {
	  oSpace = ["\n"];
	} else {
	  oSpace.push("\n");
	}
	var nSpace = n.match(/\s+/g);
	if (nSpace == null) {
	  nSpace = ["\n"];
	} else {
	  nSpace.push("\n");
	}
  
	var os = "";
	for (var i = 0; i < out.o.length; i++) {  
		if (out.o[i].text != null) {
			os += out.o[i].text + oSpace[i];
		} else {
			os += '<span class="k-diff-old">' + out.o[i] + oSpace[i] + '</span>';
		}
	}
  
	var ns = "";
	for (var i = 0; i < out.n.length; i++) {
		if (out.n[i].text != null) {
			ns += out.n[i].text + nSpace[i];
		} else {
			ns += '<span class="k-diff-new">' + out.n[i] + nSpace[i] + '</span>';
		}
	}
  
	return { o : os , n : ns };
}

// string_similarity fns; also used in Satchel
// return only the common text between o and n; for use in string_similarity fns below
U.common_text = function ( o, n ) {
	function diff( o, n ) {
		var ns = {}
		var os = {}
	
		for ( var i = 0; i < n.length; i++ ) {
		  if (!ns.hasOwnProperty(n[i])) ns[ n[i] ] = { rows: [], o: null };
		  ns[ n[i] ].rows.push( i );
		}
	
		for ( var i = 0; i < o.length; i++ ) {
		  if (!os.hasOwnProperty(o[i])) os[ o[i] ] = { rows: [], n: null };
		  os[ o[i] ].rows.push( i );
		}
	
		for ( var i in ns ) {
		  if ( ns[i].rows.length == 1 && os.hasOwnProperty(i) && os[i].rows.length == 1 ) {
			n[ ns[i].rows[0] ] = { text: n[ ns[i].rows[0] ], row: os[i].rows[0] };
			o[ os[i].rows[0] ] = { text: o[ os[i].rows[0] ], row: ns[i].rows[0] };
		  }
		}
	
		for ( var i = 0; i < n.length - 1; i++ ) {
		  if ( n[i].text != null && n[i+1].text == null && n[i].row + 1 < o.length && o[ n[i].row + 1 ].text == null && 
			   n[i+1] == o[ n[i].row + 1 ] ) {
			n[i+1] = { text: n[i+1], row: n[i].row + 1 };
			o[n[i].row+1] = { text: o[n[i].row+1], row: i + 1 };
		  }
		}
	
		for ( var i = n.length - 1; i > 0; i-- ) {
		  if ( n[i].text != null && n[i-1].text == null && n[i].row > 0 && o[ n[i].row - 1 ].text == null && 
			   n[i-1] == o[ n[i].row - 1 ] ) {
			n[i-1] = { text: n[i-1], row: n[i].row - 1 };
			o[n[i].row-1] = { text: o[n[i].row-1], row: i - 1 };
		  }
		}
	
		return { o: o, n: n };
	}

	o = $.trim(o)
	n = $.trim(n)
	
	var out = diff(o == "" ? [] : o.split(/\s+/), n == "" ? [] : n.split(/\s+/) );
  
	var oSpace = o.match(/\s+/g);
	if (oSpace == null) {
	  oSpace = [" "];
	} else {
	  oSpace.push(" ");
	}
	var nSpace = n.match(/\s+/g);
	if (nSpace == null) {
	  nSpace = [" "];
	} else {
	  nSpace.push(" ");
	}
  
	var os = "";
	for (var i = 0; i < out.o.length; i++) {  
		if (out.o[i].text != null) {
			os += out.o[i].text + oSpace[i];
		}
	}

	return $.trim(os)
}

// for fns below
U.clean_string_for_similarity = function(s) {
	s = s.toLowerCase()
	s = s.replace(/<.*?>/g, '')		// clear tags
	s = s.replace(/(\w+)/g, '$1 ')	// separate words
	s = s.replace(/\s+/g, ' ')		// collapse spaces
	s = $.trim(s)
	return s
}

U.string_similarity_chars = function(s1, s2) {
	if (empty(s1) || empty(s2)) return 0

	// clean s1 and s2 for our process; remove spaces, and split into one-character "words
	s1 = U.clean_string_for_similarity(s1)
	s2 = U.clean_string_for_similarity(s2)

	s1 = s1.replace(/\s+/g, '')
	s2 = s2.replace(/\s+/g, '')

	s1 = $.trim(s1.replace(/(.)/g, '$1 '))
	s2 = $.trim(s2.replace(/(.)/g, '$1 '))
	// console.log(s1, '|', s2)

	// then use string_similarity_words to compare
	return U.string_similarity_words(s1, s2)
}

U.string_similarity_words = function(s1, s2) {
	if (empty(s1) || empty(s2)) return 0

	// clean s1 and s2 for our process
	s1 = U.clean_string_for_similarity(s1)
	s2 = U.clean_string_for_similarity(s2)

	// get the common text between s1 and s2
	let ct = U.common_text(s1, s2)

	// if nothing in common, return 0
	if (!ct) return 0

	// split into words
	s1 = s1.split(' ')
	s2 = s2.split(' ')
	ct = ct.split(' ')

	// return the # of words in common divided by the number of words in the longer of the two strings
	// (if we use the shorter string length as the denominator, 'the ear' would have a similarity of 100 to 'ear'
	let den = (s1.length > s2.length) ? s1.length : s2.length

	// console.log(s1.join(' '))
	// console.log(ct.join(' '))
	// console.log(s2.join(' '))
	// console.log(sr('$1 / $2 = $3', ct.length, s2.length, ct.length / den))

	return ct.length / den
}

// highcharts theme chooser
U.apply_chart_theme = function(options) {
	if (vapp.$store.getters.dark_mode) {
		// dark mode colors
		var theme_colors = ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066', '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee']
		var text_color = '#E0E0E3'
		var pb_color = '#606063'
		var line_color = '#707073'
		var minor_line_color = '#505053'
		var default_marker_color = '#333'
		var legend_text_color = '#C0C0C0'
		var legend_background_color = 'rgba(0, 0, 0, 0.5)'
		var legend_item_hover_color = '#FFF'
	} else {
		// light mode colors
		var theme_colors = ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066', '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee']
		var text_color = '#111'
		var pb_color = '#606063'
		var line_color = '#707073'
		var minor_line_color = '#505053'
		var default_marker_color = '#333'
		var legend_text_color = '#222'
		var legend_background_color = 'rgba(255, 255, 255, 0.5)'
		var legend_item_hover_color = '#000'
	}				

	let theme = {
		colors: theme_colors,
		chart: {
			plotBorderColor: pb_color
		},
		title: {
			style: {
				color: text_color,
			}
		},
		subtitle: {
			style: {
				color: text_color,
			}
		},
		xAxis: {
			gridLineColor: line_color,
			labels: {
				style: {
					color: text_color,
				}
			},
			lineColor: line_color,
			minorGridLineColor: minor_line_color,
			tickColor: line_color,
			title: {
				style: {
					color: text_color,
				}
			}
		},
		yAxis: {
			gridLineColor: line_color,
			labels: {
				style: {
					color: text_color,
				}
			},
			lineColor: line_color,
			minorGridLineColor: minor_line_color,
			tickColor: line_color,
			title: {
				style: {
					color: text_color,
				},
			}
		},
		plotOptions: {
			series: {
				dataLabels: {
					color: text_color,
				},
				marker: {
					lineColor: default_marker_color
				}
			},
			boxplot: {
				fillColor: minor_line_color
			},
			candlestick: {
				lineColor: text_color
			},
			errorbar: {
				color: text_color
			}
		},
		legend: {
			backgroundColor: legend_background_color,
			itemStyle: {
				color: text_color
			},
			itemHoverStyle: {
				color: legend_item_hover_color
			},
			itemHiddenStyle: {
				color: pb_color
			},
			title: {
				style: {
					color: legend_text_color
				}
			}
		},
		labels: {
			style: {
				color: line_color
			}
		},
		drilldown: {
			activeAxisLabelStyle: {
				color: text_color
			},
			activeDataLabelStyle: {
				color: text_color
			}
		},
		navigation: {
			buttonOptions: {
				symbolStroke: text_color,
				theme: {
					fill: minor_line_color
				}
			}
		},
	}

	for (let prop in theme) {
		if (!options[prop]) options[prop] = theme[prop]
		else options[prop] = $.extend(true, theme[prop], options[prop])
	}

	return options
}

// Sound effects, using howler
// https://medium.com/game-development-stuff/how-to-create-audiosprites-to-use-with-howler-js-beed5d006ac1
// audiosprite --output sparklsounds2 -f howler --export ogg *.mp3 *.wav
// audiosprite --output sparklsounds2 -f howler --export mp3 *.mp3 *.wav
U.sounds = {
	src_file: '',	// specified in js/sounds.js;

	sprite: {
	  "complete_blank": [
		0,
		2953.378684807256
	  ],
	  "complete_exercise": [
		4000,
		1819.0249433106578
	  ],
	  "pop_1": [	// correct guess
		7000,
		996.8934240362817
	  ],
	  "pop_2": [	// incorrect guess
		9000,
		996.8934240362817
	  ],
	  "pop_3": [	// hint
		11000,
		996.8934240362817
	  ],
	  "pop_4": [
		13000,
		748.9569160997736
	  ],
	  "pop_click": [	// open blank
		15000,
		480.7709750566893
	  ]
	},

	alias: {
		complete: 'sound_1',
		incorrect: 'sound_2',
	},

	// U.sounds.on = false // turn off sound effects
	on: true,

	// default_volume: 0.1,
	// volume increased 2/23/2024, because now students can turn sounds off if they want
	default_volume: 0.2,
}

U.sounds.initialize = function() {
	if (U.sounds.loaded === true) return

	// delay so nothing else will wait for sound initialization to finish
	setTimeout(()=>{
		U.sounds.howl = new Howl({
			// src: ['/audio/sparklsounds.ogg', '/audio/sparklsounds.mp3'],
			src: [sr('/audio/$1.ogg', U.sounds.src_file), sr('/audio/$1.mp3', U.sounds.src_file)],
			volume: U.sounds.default_volume,
			sprite: U.sounds.sprite,
		})

		U.sounds.howl.once('load', ()=>{
			U.sounds.loaded = true
			console.log('sounds loaded!')
		})
	}, 0)
}

U.sounds.play = function(id, volume) {
	if (!U.sounds.loaded || !U.sounds.on) return

	// id can be in sprite directly, or via alias
	let sprite_id = id
	if (empty(U.sounds.sprite[sprite_id])) {
		sprite_id = U.sounds.alias[id]
	}

	if (sprite_id == 'none') {
		// explicitly don't play this
	} else if (empty(U.sounds.sprite[sprite_id])) {
		console.log(sr('Tried to play nonexistant sound effect “$1”', id))
	} else {
		// console.log('play ' + id)

		let howl_id = U.sounds.howl.play(sprite_id)
		// set volume on this sound if sent
		if (!empty(volume)) U.sounds.howl.volume(volume, howl_id)
	}
}

// utilities for using local storage to save and retrieve settings
U.local_storage_set = function(key, val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	// can't set to localStorage in safari incognito mode
	try {
		window.localStorage.setItem(key, JSON.stringify(val));
	} catch(e) {
		console.log('error in local_storage_set', e)
	}
}

U.local_storage_clear = function(key) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	window.localStorage.removeItem(key);
}

U.local_storage_get = function(key, default_val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	let val = window.localStorage.getItem(key)
	if (!empty(val)) {
		try {
			val = JSON.parse(val);
		} catch(e) {
			console.log('error parsing JSON in local_storage_get: ' + val)
			val = ''
		}
	}

	if (empty(val)) {
		return default_val
	} else {
		// do some type checking
		if (default_val === true || default_val === false) {
			if (val === 'true') val = true
			if (val === 'false') val = false
			if (val != true && val != false) {
				throw new Error(sr('Boolean argument expected for key $1; $2 received', key, val))
			}

		} else if (typeof(default_val) == 'number') {
			if (isNaN(val*1)) {
				throw new Error(sr('Numeric argument expected for key $1; $2 received', key, val))
			}
			val = val * 1

		} else if (typeof(default_val) == 'string') {
			if (typeof(val) != 'string') {
				throw new Error(sr('String argument expected for key $1; $2 received', key, val))
			}
			val = val + ''
		}
		return val
	}
}

U.cookie_set = function(key, val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	document.cookie = sr('$1=$2', key, val)
}

U.cookie_clear = function(key) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
}

U.cookie_get = function(key, default_val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	let cookies = document.cookie.split(';')
	for (let c of cookies) {
		let arr = c.split('=')
		if ($.trim(arr[0]) == key) {
			let val = arr[1]
			// do some type checking
			if (default_val === true || default_val === false) {
				if (val === 'true') val = true
				if (val === 'false') val = false
				if (val != true && val != false) {
					throw new Error(sr('Boolean argument expected for key $1; $2 received', key, val))
				}

			} else if (typeof(default_val) == 'number') {
				if (isNaN(val*1)) {
					throw new Error(sr('Numeric argument expected for key $1; $2 received', key, val))
				}
				val = val * 1

			} else if (typeof(default_val) == 'string') {
				if (typeof(val) != 'string') {
					throw new Error(sr('String argument expected for key $1; $2 received', key, val))
				}
				val = val + ''
			}
			return val
		}
	}
}

U.data_url_to_blob = function(data_url) {
    let arr = data_url.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type:mime})
}


U.meta_or_alt_key = function(evt) {
	// key on metaKey ("command") for macos and altKey ("alt") for windows ("alt" on mac keyboard *isn't* what we want)
	if (!evt) return false	// shouldn't happen
	if (window.navigator.platform.indexOf('Win') > -1 && evt.altKey === true) return true
	if (window.navigator.platform.indexOf('Mac') > -1 && evt.metaKey === true) return true
	return false
}

U.meta_or_alt_key_noun = function() {
	// sr('Hold down the $1 key while you click…', U.meta_or_alt_key_noun())
	if (window.navigator.platform.indexOf('Mac') > -1) return 'COMMAND'
	return 'ALT'
}

U.meta_or_alt_key_symbol = function() {
	// sr('Hold down the $1 key while you click…', U.meta_or_alt_key_symbol())
	if (window.navigator.platform.indexOf('Mac') > -1) return '⌘'
	return 'ALT'
}

// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)
// Returns a function, that, as long as it continues to be invoked, will not be triggered.
// The function will be called after it stops being called for N milliseconds.
// If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
// To call:
	// // establish the debounce fn if necessary
	// if (empty(this.fn_debounced)) {
	// 	this.fn_debounced = U.debounce(function(x) {
	// 		...
	// 	}, 1000)
	// }
	// // call the debounce fn
	// this.fn_debounced(x)
U.debounce = function(func, wait, immediate) {
  	var timeout

  	// This is the function that is actually executed when the DOM event is triggered.
  	return function executedFunction() {
    	// Store the context of this and any parameters passed to executedFunction
    	var context = this
    	var args = arguments

    	// The function to be called after the debounce time has elapsed
    	var later = function() {
      		// null timeout to indicate the debounce ended
      		timeout = null

      		// Call function now if you did not on the leading end
      		if (!immediate) func.apply(context, args)
    	}

    	// Determine if you should call the function on the leading or trail end
    	var callNow = immediate && !timeout

    	// This will reset the waiting every function execution. This is the step that prevents the function
    	// from being executed because it will never reach the inside of the previous setTimeout
    	clearTimeout(timeout)

    	// Restart the debounce waiting period. setTimeout returns a truthy value (it differs in web vs node)
    	timeout = setTimeout(later, wait)

    	// Call immediately if you're dong a leading end execution
    	if (callNow) func.apply(context, args)
  	}
}

// apply a shake animation to the given element (specified as jquery)
// dir is 'h' or 'v'; add an id if multiple things are likely to be shaken in quick succession; otherwise the clearTimeouts will conflict
U.shake = function(jq, dir, id) {
	if (empty(id)) id = 'x'

	// clear previous timeout if found
	if (!empty(U['shake_timeout_' + id])) clearTimeout(U['shake_timeout_' + id])

	// add shake class
	jq.addClass('k-shake-' + dir)

	// remove class after delay for shake
	U['shake_timeout_' + id] = setTimeout(x=>jq.removeClass('k-shake-' + dir), 600)
}

/*
seedrandom

// Make a predictable pseudorandom number generator.
// AS OF 6/2021, the below fn doesn't work; use `npm i seedrandom` with this in main.js:
// import seedrandom from 'seedrandom'
// Math.seedrandom = seedrandom

var myrng = new Math.seedrandom('hello.');
console.log(myrng());                // Always 0.9282578795792454
console.log(myrng());                // Always 0.3752569768646784
*/
// !function(f,a,c){var s,l=256,p="random",d=c.pow(l,6),g=c.pow(2,52),y=2*g,h=l-1;function n(n,t,r){function e(){for(var n=u.g(6),t=d,r=0;n<g;)n=(n+r)*l,t*=l,r=u.g(1);for(;y<=n;)n/=2,t/=2,r>>>=1;return(n+r)/t}var o=[],i=j(function n(t,r){var e,o=[],i=typeof t;if(r&&"object"==i)for(e in t)try{o.push(n(t[e],r-1))}catch(n){}return o.length?o:"string"==i?t:t+"\0"}((t=1==t?{entropy:!0}:t||{}).entropy?[n,S(a)]:null==n?function(){try{var n;return s&&(n=s.randomBytes)?n=n(l):(n=new Uint8Array(l),(f.crypto||f.msCrypto).getRandomValues(n)),S(n)}catch(n){var t=f.navigator,r=t&&t.plugins;return[+new Date,f,r,f.screen,S(a)]}}():n,3),o),u=new m(o);return e.int32=function(){return 0|u.g(4)},e.quick=function(){return u.g(4)/4294967296},e.double=e,j(S(u.S),a),(t.pass||r||function(n,t,r,e){return e&&(e.S&&v(e,u),n.state=function(){return v(u,{})}),r?(c[p]=n,t):n})(e,i,"global"in t?t.global:this==c,t.state)}function m(n){var t,r=n.length,u=this,e=0,o=u.i=u.j=0,i=u.S=[];for(r||(n=[r++]);e<l;)i[e]=e++;for(e=0;e<l;e++)i[e]=i[o=h&o+n[e%r]+(t=i[e])],i[o]=t;(u.g=function(n){for(var t,r=0,e=u.i,o=u.j,i=u.S;n--;)t=i[e=h&e+1],r=r*l+i[h&(i[e]=i[o=h&o+t])+(i[o]=t)];return u.i=e,u.j=o,r})(l)}function v(n,t){return t.i=n.i,t.j=n.j,t.S=n.S.slice(),t}function j(n,t){for(var r,e=n+"",o=0;o<e.length;)t[h&o]=h&(r^=19*t[h&o])+e.charCodeAt(o++);return S(t)}function S(n){return String.fromCharCode.apply(0,n)}if(j(c.random(),a),"object"==typeof module&&module.exports){module.exports=n;try{s=require("crypto")}catch(n){}}else"function"==typeof define&&define.amd?define(function(){return n}):c["seed"+p]=n}("undefined"!=typeof self?self:this,[],Math);

// https://stackoverflow.com/questions/1293147/javascript-code-to-parse-csv-data
window.CSV = {
	parse: function(csv, reviver) {
	    reviver = reviver || function(r, c, v) { return v; };
	    var chars = csv.split(''), c = 0, cc = chars.length, start, end, table = [], row;
	    while (c < cc) {
	        table.push(row = []);
	        while (c < cc && '\r' !== chars[c] && '\n' !== chars[c]) {
	            start = end = c;
	            if ('"' === chars[c]){
	                start = end = ++c;
	                while (c < cc) {
	                    if ('"' === chars[c]) {
	                        if ('"' !== chars[c+1]) { break; }
	                        else { chars[++c] = ''; } // unescape ""
	                    }
	                    end = ++c;
	                }
	                if ('"' === chars[c]) { ++c; }
	                while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { ++c; }
	            } else {
	                while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { end = ++c; }
	            }
	            row.push(reviver(table.length-1, row.length, chars.slice(start, end).join('')));
	            if (',' === chars[c]) { ++c; }
	        }
	        if ('\r' === chars[c]) { ++c; }
	        if ('\n' === chars[c]) { ++c; }
	    }
	    return table;
	},

	stringify: function(table, replacer) {
	    replacer = replacer || function(r, c, v) { return v; };
	    var csv = '', c, cc, r, rr = table.length, cell;
	    for (r = 0; r < rr; ++r) {
	        if (r) { csv += '\r\n'; }
	        for (c = 0, cc = table[r].length; c < cc; ++c) {
	            if (c) { csv += ','; }
	            cell = replacer(r, c, table[r][c]);
	            if (/[,\r\n"]/.test(cell)) { cell = '"' + cell.replace(/"/g, '""') + '"'; }
	            csv += (cell || 0 === cell) ? cell : '';
	        }
	    }
	    return csv;
	}
};

// simple TSV equivalent to CSV fns above
window.TSV = {
	parse: function(tsv) {
		let lines = tsv.split('\n')
		for (let i = 0; i < lines.length; ++i) {
			let line = $.trim(lines[i])

			if (empty(line)) {
				lines[i] = []
			} else {
				lines[i] = line.split('\t')
			}
		}
		return lines
	},

	stringify:function(lines) {
		let tsv = ''
		for (let i = 0; i < lines.length; ++i) {
			tsv += lines[i].join('\t') + '\n'
		}
		return tsv
	}
};

// compress an image selected by the user from the filesystem (or a picture taken with a phone camera) client-side, and return the dataURL that represents the image
U.create_image_data_url = function(image_file, params) {
	// console.log('create_image_data_url: ' + params.max_width)
	// params: max_width is required; max_height is optional
	let max_width = params.max_width
	let max_height = params.max_height
	// required callback fn to receive the image data_url and placeholder
	let callback_fn = params.callback_fn

	// 0.9 = slightly compressed jpg
	let compression_level = dv(params.compression_level, 0.9)

	let image_format = dv(params.image_format, 'webp')	// jpeg, webp, or png; png is the only one that's required for browsers to support...

	// create a canvas element to do the conversion
	let canvas = document.createElement("canvas")

	// load the image into memory using a FileReader to get the image size
	let reader = new FileReader()
	reader.onload = e => {
		let image = new Image()
		image.onload = e => {
			let natural_image_width = (image.naturalWidth) ? image.naturalWidth : image.width
			let natural_image_height = (image.naturalHeight) ? image.naturalHeight : image.height

			let scaled_image_width, scaled_image_height
			// if image is smaller than width/height, or if max_width is 'full', return as is
			if (max_width == 'full' || natural_image_width < max_width*1) {
				scaled_image_width = natural_image_width
				scaled_image_height = natural_image_height
			} else {
				// else start by scaling to make the image width fit in the max_height
				scaled_image_width = max_width
				scaled_image_height = natural_image_height / (natural_image_width / scaled_image_width)
			}

			// if we have max_height and this makes the height taller than max_height, scale to make the image height fit in the vertical space
			if (max_height && max_height != 'full' && scaled_image_height > max_height) {
				scaled_image_height = max_height
				scaled_image_width = natural_image_width / (natural_image_height / scaled_image_height)
			}

			// set the canvas width, then draw the image to the canvas
			canvas.width = scaled_image_width
			canvas.height = scaled_image_height
			canvas.getContext('2d').drawImage(image, 0, 0, scaled_image_width, scaled_image_height)

			// extract the image dataURL
			let img_url = canvas.toDataURL('image/' + image_format, compression_level)
			// console.log('img_url size:' + img_url.length)

			callback_fn({
				img_url: img_url,
				width: scaled_image_width,
				height: scaled_image_height,
				natural_width: natural_image_width,
				natural_height: natural_image_height,
			})
		}
		image.onerror = e => {
			console.log('IMAGE ONERROR!!', e)
			callback_fn({error: e, source:'image'})
		}
		image.src = e.target.result
	}
	reader.onerror = e => {
		console.log('READER ONERROR!', e)
		callback_fn({error: e, source:'reader'})
	}
	// trigger the FileReader to load the image file
	reader.readAsDataURL(image_file)
}

// this is a promise-based version of create_image_data_url; it allows us to do this:
/*
asyc outer_fn() {
	let rv = await U.create_image_data_url_promise(file0, {image_format: 'webp', max_width: 'full'})
	// we now have immediate access to rv
}
*/
U.create_image_data_url_promise = function(image_file, params) {
	return new Promise((resolve, reject) => {
		params.callback_fn = resolve
		U.create_image_data_url(image_file, params)
	})
}

U.data_url_to_blob = function(data_url) {
    let arr = data_url.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type:mime})
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
U.format_bytes = function(bytes, decimals) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = (!decimals || decimals < 0) ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/*
date and time functions:
npm i date-and-time
https://github.com/knowledgecode/date-and-time

in main.js:
import date from 'date-and-time'
import 'date-and-time/plugin/meridiem';
date.plugin('meridiem')
window.date = date

to convert from timestamp:
date.format(new Date(this.entry.date_added*1000), 'MMMM D, YYYY h:mm A')	// January 1, 2019 3:12 PM
date.format(new Date(this.post.date_created*1000), 'MMM D, YYYY h:mm A')	// Jan 1, 2019 3:12 PM

to convert from mysql date to js Date, then to an alternate format:
let d = date.parse(data.results.created_at, 'YYYY-MM-DD HH:mm:ss')
date.format(d, 'MMM D, YYYY h:mm A')	// Jan 3, 2020 3:04 PM

token   meaning	  example
YYYY    year      0999, 2015
YY      year      05, 99
Y       year      2, 44, 888, 2015
MMMM    month     January, December
MMM     month     Jan, Dec
MM      month     01, 12
M       month     1, 12
DDD (*) day       1st, 2nd, 3rd
DD      day       02, 31
D       day       2, 31
dddd    day/week  Friday, Sunday
ddd     day/week  Fri, Sun
dd      day/week  Fr, Su
HH      24-hour   23, 08
H       24-hour   23, 8
A       meridiem  AM, PM
a (*)   meridiem  am, pm
AA (*)  meridiem  A.M., P.M.
aa (*)  meridiem  a.m., p.m.
hh      12-hour   11, 08
h       12-hour   11, 8
mm      minute    14, 07
m       minute    14, 7
ss      second    05, 10
s       second    5, 10
SSS     msec      753, 022
SS      msec      75, 02
S       msec      7, 0
Z       timezone  +0100, -0800
*/
